import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import NichtNeu from '@/assets/icons/NichtNeu.svg'
import NichtNeuHover from '@/assets/icons/NichtNeuHover.svg'

const IconBox = styled(Box)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10000;
  & div {
    width: inherit !important;
    height: inherit !important;
  }
  -webkit-tap-highlight-color: transparent;
`

const Icon = styled(Box)`
  background-repeat: no-repeat;
  background-image: url(${NichtNeu});
  background-position: center;
  background-size: contain;
  @media screen and (min-width: 1025px) {
    :hover {
      background-image: url(${NichtNeuHover});
    }
  }
`

type LogoProps = {
  inactive?: boolean
  logoType: string
}

const Logo = (props: LogoProps): JSX.Element => {
  const { logoType } = props
  const [iconName, setIconName] = useState('NichtNeu')

  const setNichtNeu = () => {
    setIconName('NichtNeu')
  }

  const setLogoType = () => {
    setIconName(logoType)
  }

  return (
    <IconBox onMouseEnter={setLogoType} onMouseLeave={setNichtNeu}>
      <Icon></Icon>
    </IconBox>
  )
}

export default Logo
