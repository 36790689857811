import React, { FC, ReactChild, useEffect, useState } from 'react'
import { Container, CssBaseline } from '@material-ui/core'
import GlobalStyle from '@/styles/theme'
import { ThemeProvider } from '@material-ui/styles'
import theme from '@/styles/MUItheme'
import Head from './Head'
import Navigation from '@/components/Navigation/Navigation'
import Footer from '@/components/Footer'
import { NavigationProvider } from '@/contexts/NavigationContext'
import { StoresProvider } from '../contexts/StoresContext'
import { MetaData } from '@/containers/Head'
import { useScript } from 'usehooks-ts'

type Props = {
  children: ReactChild | ReactChild[] | JSX.Element
  footerIsHalf?: boolean
  displayNone?: boolean
  useLightBackground?: boolean | null
  meta?: MetaData
}

const activateGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', 'G-FBD41HMWTL')
}

const Layout: FC<Props> = props => {
  const { children, footerIsHalf, displayNone, useLightBackground, meta } = props
  const lightBlue = theme.palette.secondary.main
  const royalBlue = theme.palette.background.default
  const [gaScript, setGaScript] = useState<string | null>(null)

  useScript(gaScript)

  useEffect(() => {
    setGaScript('https://www.googletagmanager.com/gtag/js?id=G-FBD41HMWTL')
    activateGoogleAnalytics()
  }, [])


  return (
    <Container disableGutters={true} maxWidth={false}>
      <Head meta={meta}/>
      <ThemeProvider
        theme={
          useLightBackground
            ? {
                ...theme,
                palette: {
                  ...theme.palette,
                  secondary: {
                    ...theme.palette.secondary,
                    main: royalBlue
                  },
                  background: {
                    paper: lightBlue,
                    default: lightBlue
                  },
                  text: {
                    ...theme.palette.text,
                    primary: royalBlue
                  }
                }
              }
            : theme
        }
      >
        <StoresProvider>
          <NavigationProvider>
            <CssBaseline />
            <GlobalStyle />
            <Navigation />
            {children}
            <Footer displayNone={displayNone} half={footerIsHalf} />
          </NavigationProvider>
        </StoresProvider>
      </ThemeProvider>
    </Container>
  )
}

export default Layout
