import React, { FC, useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Box, Button, Typography, InputBase } from '@material-ui/core'
import styled from 'styled-components'
import Instagram from '@/assets/icons/Instagram.inline.svg'
import InstagramHover from '@/assets/icons/InstagramHover.inline.svg'
import Smiley from '@/assets/icons/UeberUns.inline.svg'
import SmileyHover from '@/assets/icons/UeberUnsHover.inline.svg'
import Guide from '@/assets/icons/Guide.inline.svg'
import GuideHover from '@/assets/icons/GuideHover.inline.svg'
import Market from '@/assets/icons/Market.inline.svg'
import MarketHover from '@/assets/icons/MarketHover.inline.svg'
import { theme } from '@/styles/theme'

const StyledTextField = styled(InputBase)`
  width 100%;
  margin: 0;
  input {
    box-sizing: border-box;
    background: ${theme.colors.blue};
    border: 3px solid white;
    border-radius: 2rem;
    height: 3rem;
    padding: 0;
    padding-left: 2rem;
    padding-top: 0.2rem;
    font-size: 1.5rem;
    font-weight: 700;

  }
  .MuiInputBase-input::placeholder {
    opacity: 1;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.6 !important;
    height: 3rem;
  }

  input::placeholder {
    padding-top: 0.7rem;
    line-height: 1.6 !important;
  }
`

const OkBox = styled(Box)`
  height: 3rem;
  padding-right: 1rem;
  padding-top: calc(0.2rem + 2px);
  font-size: 1.5rem;
  font-weight: 700;
  :hover {
    cursor: pointer;
    color: black;
  }
  display: flex;
  align-items: center;
`

const IconBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 13rem;
  height: 13rem;
  cursor: pointer;

  @media screen and (max-width: 1400px) and (min-width: 1024px) {
    ${p => p.half && 'width: 9vw; height: 9vw;'}
  }
`

type FooterProps = {
  half?: boolean
  displayNone?: boolean
}

const Footer: FC<FooterProps> = props => {
  const { half, displayNone } = props

  const [smileyHover, setSmileyHover] = useState(false)
  const [instagramHover, setInstagramHover] = useState(false)
  const [marketHover, setMarketHover] = useState(false)
  const [guideHover, setGuideHover] = useState(false)

  const { sanitySiteSettingsNavigation } = useStaticQuery(graphql`
    {
      sanitySiteSettingsNavigation {
        instagramUrl
      }
    }
  `)

  const { instagramUrl } = sanitySiteSettingsNavigation

  return (
    <Box width={'100%'} bgcolor={theme.colors.blue}>
      <Box
        display={displayNone ? 'none' : 'flex'}
        pt={half ? 0 : {xs: 6, sm: 9}}
        pb={half ? 0 : 9}
        px={{ xs: 3, sm: 0 }}
        margin={half ? '5rem 9rem 9rem 5rem' : 'auto'}
        flexDirection="column"
        alignSelf="center"
        maxWidth={'72rem'}
      >
        <Box>
          <div id="mc_embed_signup">
            <form
              action="https://nichtneu.us5.list-manage.com/subscribe/post?u=e9c159217d88dfee33912c0e5&amp;id=5aa32fd8bb"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <div id="mc_embed_signup_scroll">
                <Box
                  className="mc-field-group"
                  display="flex"
                  position="relative"
                  width="100%"
                >
                  <StyledTextField
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    style={{ color: theme.colors.white }}
                    placeholder="Abonniere uns mit Deiner E-Mail Adresse..."
                  ></StyledTextField>
                  <Box
                    position="absolute"
                    p="0"
                    right="1rem"
                    height="3rem"
                    top="0"
                  >
                    <OkBox
                      onClick={() => {
                        document
                          .querySelector('#mc-embedded-subscribe-form')
                          .submit()
                      }}
                    >
                      <Typography
                        style={{ color: theme.colors.white }}
                        variant="h6"
                        component="div"
                      >
                        OK!
                      </Typography>
                    </OkBox>
                  </Box>
                </Box>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  ></div>
                </div>
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_e9c159217d88dfee33912c0e5_5aa32fd8bb"
                    tabIndex={-1}
                    value=""
                  ></input>
                </div>
              </div>
            </form>
          </div>
        </Box>
        <Box
          display="flex"
          justifyContent={{ xs: 'space-evenly', sm: 'space-between' }}
          flexWrap="wrap"
          pt={2}
        >
          <IconBox
            half={half}
            onMouseEnter={() => {
              setSmileyHover(true)
            }}
            onMouseLeave={() => {
              setSmileyHover(false)
            }}
            onClick={() => {
              navigate('/ueber-uns')
            }}
          >
            {smileyHover ? <SmileyHover /> : <Smiley />}
            <Typography
              style={{ color: theme.colors.white }}
              variant="h6"
              component="h5"
            >
              Über uns
            </Typography>
          </IconBox>
          <a
            href={instagramUrl}
            target="_blank"
            style={{ textDecoration: 'none' }}
            rel="noreferrer"
          >
            <IconBox
              half={half}
              onMouseEnter={() => {
                setInstagramHover(true)
              }}
              onMouseLeave={() => {
                setInstagramHover(false)
              }}
            >
              {instagramHover ? <InstagramHover /> : <Instagram />}

              <Typography
                style={{ color: theme.colors.white }}
                variant="h6"
                component="h5"
              >
                Instagram
              </Typography>
            </IconBox>
          </a>
          <Box flexBasis={{ xs: '100%' }} display={{ sm: 'none' }} height="0" />
          <IconBox
            half={half}
            onMouseEnter={() => {
              setMarketHover(true)
            }}
            onMouseLeave={() => {
              setMarketHover(false)
            }}
            onClick={() => {
              navigate('/markt')
            }}
          >
            {marketHover ? <MarketHover /> : <Market />}
            <Typography
              style={{ color: theme.colors.white }}
              variant="h6"
              component="h5"
            >
              Markt
            </Typography>
          </IconBox>
          <IconBox
            half={half}
            onMouseEnter={() => {
              setGuideHover(true)
            }}
            onMouseLeave={() => {
              setGuideHover(false)
            }}
            onClick={() => {
              navigate('/buch')
            }}
          >
            {guideHover ? <GuideHover /> : <Guide />}
            <Typography
              style={{ color: theme.colors.white }}
              variant="h6"
              component="h5"
            >
              Unser Guide
            </Typography>
          </IconBox>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
