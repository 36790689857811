import React, { useState, FC, useEffect } from 'react'
import { SanityStore } from 'web/graphql-types'

type centerData = {
  lat: number
  lng: number
}

interface StoresProps {
  allStores: { [id: string]: SanityStore }
  setAllStores: () => void
  // activeStores are the stores display in the list / not filtered
  activeStores: SanityStore[]
  setActiveStores: (stores: SanityStore[]) => void
  // currentStore is the opened item
  currentStore: string | null
  setCurrentStore: (store: string | null) => void
  focusedStore: string | null
  // focused Store is the Store highlighted
  setFocusedStore: (store: string | null) => void
  activeTags: string[]
  setActiveTags: (tags: string[]) => void
  imageView: boolean
  setImageView: (imageView: boolean) => void
  center: centerData
  setCenter: (center: centerData) => void
  zoom: number
  setZoom: (val: number) => void
}

const contextDefaultValues: StoresProps = {
  allStores: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAllStores: () => {},
  activeStores: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveStores: () => {},
  currentStore: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentStore: () => {},
  focusedStore: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFocusedStore: () => {},
  activeTags: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveTags: () => {},
  imageView: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImageView: () => {},
  center: { lat: 47.375, lng: 8.53 },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCenter: () => {},
  zoom: 13,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setZoom: () => {}
}

export const StoresContext = React.createContext<StoresProps>(
  contextDefaultValues
)

export const StoresProvider: FC = ({ children }) => {
  const [allStores, setAllStores] = useState<{ [id: string]: SanityStore }>(
    contextDefaultValues.allStores
  )
  const [activeStores, setActiveStores] = useState<SanityStore[]>(
    contextDefaultValues.activeStores
  )
  const [currentStore, setCurrentStore] = useState<string | null>(
    contextDefaultValues.currentStore
  )
  const [focusedStore, setFocusedStore] = useState<string | null>(
    contextDefaultValues.focusedStore
  )
  const [activeTags, setActiveTags] = useState<string[]>(
    contextDefaultValues.activeTags
  )
  const [imageView, setImageView] = useState<boolean>(
    contextDefaultValues.imageView
  )
  const [center, setCenter] = useState(contextDefaultValues.center)
  const [zoom, setZoom] = useState(contextDefaultValues.zoom)

  const filterStoresByTags = () => {
    const zuTagActive = activeTags.includes('zu')
    const storeBase = zuTagActive ?
      Object.values(allStores).filter(store => store?.tags?.map(t => t?.title).includes('zu')) :
      Object.values(allStores).filter(store => !store?.tags?.map(t => t?.title).includes('zu'))

    if (!activeTags.length) setActiveStores(storeBase)
    else {
      setActiveStores(
        storeBase.filter(store => {
          return activeTags.every(filter =>
            store?.tags?.map(tag => tag?.title).includes(filter)
          )
        })
      )
    }
  }

  useEffect(() => {
    filterStoresByTags()
  }, [activeTags])

  return (
    <StoresContext.Provider
      value={{
        allStores,
        setAllStores,
        activeStores,
        setActiveStores,
        currentStore,
        setCurrentStore,
        focusedStore,
        setFocusedStore,
        activeTags,
        setActiveTags,
        imageView,
        setImageView,
        center,
        setCenter,
        zoom,
        setZoom
      }}
    >
      {children}
    </StoresContext.Provider>
  )
}
