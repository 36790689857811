import React from 'react'
import MuiLink, { LinkProps } from '@material-ui/core/Link'
import { ThemeProvider } from '@material-ui/styles'
import theme from '@/styles/MUItheme'
import { GatsbyLink } from './glink'

export const Link = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & { to?: string }
>((props, ref) => {
  const { to } = props
  const themeOverride = {
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiLink: {
        root: {
          underline: 'none',
          fontWeight: 'inherit'
        }
      }
    }
  }
  return to ? (
    <ThemeProvider theme={themeOverride}>
      <MuiLink
        ref={ref}
        component={GatsbyLink}
        to={to}
        {...props}
        variant="inherit"
      />
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={themeOverride}>
      <MuiLink ref={ref} {...props} />
    </ThemeProvider>
  )
})

Link.displayName = 'Link'
