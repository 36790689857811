import React, { useState, FC } from 'react'

interface NavigationProps {
  backButton: boolean
  route: string | null
  setBackButton: (visible: boolean) => void
  setRoute: (name: string | null) => void
}

const contextDefaultValues: NavigationProps = {
  backButton: false,
  route: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBackButton: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRoute: () => {}
}

export const NavigationContext = React.createContext<NavigationProps>(
  contextDefaultValues
)

export const NavigationProvider: FC = ({ children }) => {
  const [backButton, setBackButton] = useState<boolean>(
    contextDefaultValues.backButton
  )
  const [route, setRoute] = useState<string | null>(contextDefaultValues.route)

  return (
    <NavigationContext.Provider
      value={{
        backButton,
        route,
        setBackButton,
        setRoute
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
