import { createGlobalStyle } from 'styled-components'
import { p, h4 } from '@/styles/typography'

export const theme = {
  scale: 12 / 16,
  fontSize: {
    desktop: 12,
    tablet: 10,
    mobile: 8
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1025,
      md: 1600
    }
  },
  colors: {
    blue: '#0F46FA',
    white: '#FFFFFF'
  },
  background: '#0F46FA'
}

const GlobalStyle = createGlobalStyle`
  html{
    font-size: ${theme.fontSize.mobile}px;
    @media (min-width: ${theme.breakpoints.values.sm}px) {
      font-size: ${theme.fontSize.tablet}px;
    }
    @media (min-width: ${theme.breakpoints.values.md}px) {
      font-size: ${theme.fontSize.desktop}px;
    }
  }

  body {
    overflow-x: hidden;
    hyphens: manual;
  }

  .overflow-y-hidden {
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      overflow-y: hidden;
    }
  }

  button {
    :focus-visible {
      outline: none;
    }
  } 

  a {
    color: white;
  }

  p {
    ${p}
    padding-bottom: 3rem;
  }

  p:last-of-type {
    padding-bottom: 0rem;
  }

  p:first-of-type {
    padding-bottom: 3rem;
  }

  h3 {
    ${h4}
    margin-bottom: 0.5rem;
  }

  h3,
  ol + h3,
  ul + h3 {
    margin-top: 1.5rem;
  }

  ol,
  ul {
    margin-bottom: 0.5rem;
  }

  ol {
    padding-left: 1.75rem;

    > li {
      padding-left: 0.25rem;
    }
  }

  ul {
    list-style: disc;
    padding-left: 1.5rem;

    > li {
      padding-left: 0.5rem;
    }
  }

  a {
    display: inline;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 1px;
    -webkit-tap-highlight-color: transparent;

    :focus,
    :hover {
      color: black !important;
    }
  }

  strong {
    display: inline;
    font-weight: 700;
  }

`
export default GlobalStyle
