import React, { useEffect, useState, useContext, FC } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { Box, Typography } from '@material-ui/core'
import Grid from '@/atoms/Grid'
import styled from 'styled-components'
import { Link } from '@/atoms/link'
import Logo from './Logo'
import { NavigationContext } from '../../contexts/NavigationContext'
import Menu from '@/assets/icons/menu/home.png'
import MenuHover from '@/assets/icons/menu/home-hover.png'
import Contact from '@/assets/icons/menu/mail.png'
import ContactHover from '@/assets/icons/menu/mail-hover.png'
import Smiley from '@/assets/icons/menu/ueber-uns.png'
import SmileyHover from '@/assets/icons/menu/ueber-uns-hover.png'
import withWidth from '@material-ui/core/withWidth'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import CrossSVG from '@/assets/icons/X.svg'
import { getUri } from '@/utils/routing'
import { theme } from '@/styles/theme'

gsap.registerPlugin(ScrollTrigger)

const RootGrid = styled(Grid)`
  height: 100vh;
  @media screen and (max-width: 1024px) {
    height: -webkit-fill-available;
    height: 100vh;
    padding: 5rem;
    padding-top: 12rem;
  }
  position: fixed;
  left: 0;
  padding: 8vh;
  z-index: 1000;
  background-color: #0f46fa;
  transition: top 1s;
  top: 0;
  ${p => `top: ${p.top};`}
  @media screen and (max-width: 1024px) {
    ${p => {
    if (p.opened === true)
      return `
      ::after {
        height: 150px;
        width: 100%;
        background-color: ${theme.colors.blue};
        content: "";
        position: absolute;
        bottom: -140px;
        left: 0;
      }`
  }}
  }
`

const StyledGridContainer = styled(Grid)`
  justify-content: space-evenly;
  @media screen and (min-width: 1024px) {
    justify-content: space-around;
  }
  `

const BlueBox = styled(Box)`
      height: 150px;
      width: 100%;
      background-color: ${theme.colors.blue};
      position: absolute;
      bottom: 0;
      left: 0;
      @media screen and (min-width: 1025px) {
        height: 0;
      }
`

const LogoBox = styled(Box)`
  background: none;
  border: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
`

const Cross = styled(Box)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${CrossSVG});
  transition: transform .5s;
  -webkit-tap-highlight-color: transparent;
  :hover {
    transform: rotate(90deg);
    cursor: pointer;
  }
`

const IconContainer = styled(Box)`
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    transform: translate(-10px);
  }
  @media screen and (min-width: 1025px) {
    position: absolute;
    right: 4.5rem;
    top: calc(50% + 8.75rem);
    transform: translateY(-50%);
    transition: opacity 1.5s;
    transition-duration: 0.5s;
    ${p => (p.opacity ? 'transition-delay: 1s;' : 'transition-delay: 0s;')}
    ${p => `opacity: ${p.opacity};`}
  }
  @media screen and (min-width: 1600px) {
    right: 5.5rem
  }
  -webkit-tap-highlight-color: transparent;
`

const IconLink = styled(Link)`
  cursor: pointer;
  width: 10rem;
  height: 12rem;
  display: flex;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    padding-bottom: 1rem;
  }
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: black;
  }
  list-style: none;
`

const MenuIcon = styled(Box)`
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${Menu});
  &:hover {
    background-image: url(${MenuHover});
  }
`

const ContactIcon = styled(Box)`
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${Contact});
  &:hover {
    background-image: url(${ContactHover});
  }
`

const UeberUnsIcon = styled(Box)`
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${Smiley});
  &:hover {
    background-image: url(${SmileyHover});
  }
`

const widthDict = {
  xs: '10rem',
  sm: '13rem',
  md: '15rem'
}

const Navigation: FC = ({ width }) => {
  const [opened, setOpened] = useState<boolean>(false)
  const [landing, setLanding] = useState<boolean>(false)
  const [logoHover, setLogoHover] = useState<boolean>(false)

  const { sanitySiteSettingsNavigation } = useStaticQuery(graphql`
    {
      sanitySiteSettingsNavigation {
        mainNavigationItems {
          _key
          _type
          label
          link {
            ... on SanityPage {
              _type
              slug {
                current
              }
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityStore {
              _type
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)

  const { mainNavigationItems: navigation } = sanitySiteSettingsNavigation

  const { backButton, route } = useContext(NavigationContext)
  useEffect(() => {
    if (window.location.pathname === '/') {
      setOpened(false)
      setLanding(true)
    }
  }, [])

  useEffect(() => {
    console.log(opened, document.body)
    if (opened) 
      document.body.classList.add('overflow-y-hidden')
    else
      document.body.classList.remove('overflow-y-hidden')
  }, [opened])

  useEffect(() => {
    const rootDiv = document.getElementsByClassName(
      'MuiContainer-root'
    )[0] as HTMLElement

    const handleResize = () => {
      ScrollTrigger.getAll().forEach(t => {
        t.kill()
      })
      gsap.to('.logo-box-scroll-trigger', {
        rotation: rootDiv.offsetHeight / 10,
        scrollTrigger: { scrub: 0.3 }
      })
    }
    handleResize()
    const resizeObserver = new ResizeObserver(() => {
      handleResize()
    })
    resizeObserver.observe(rootDiv)
  }, [])

  const toggleNavigation = () => {
    if (!landing) setOpened(!opened)
  }

  const onLogoClicked = () => {
    setOpened(!opened)
  }

  return (
    <Box position="relative">
      <RootGrid id="main-navigation" container direction="row" top={opened ? '-0' : '-100vh'} opened={opened} component="nav" role="navigation">
        <StyledGridContainer container xs={10} direction="column" component="ul" style={{'list-style': 'none'}}>
            { navigation.map((navItem, index) => index !== 3 && (
              <Grid item key={navItem._key} component="li">
                <StyledLink onClick={toggleNavigation} to={getUri(navItem.link.slug.current, navItem.link._type)}>
                  <Typography variant="h1">{navItem.label}</Typography>
                </StyledLink>
              </Grid>
            )) }
          <IconContainer opacity={opened ? 1 : 0} marginBottom={{xs: '5rem', sm: 0}}>
            <IconLink
              to={"/ueber-uns"}
              onClick={() => {
                setOpened(false)
              }}
            >
              <UeberUnsIcon />
            </IconLink>
            <IconLink
              to="/kontakt"
              onClick={() => {
                setOpened(false)
              }}
            >
              <ContactIcon />
            </IconLink>
            <IconLink
              to="/">
              <MenuIcon onClick={() => 
                setOpened(false) }
                />
            </IconLink>
          </IconContainer>
        </StyledGridContainer>
        <Grid container xs={1} justify="center" alignItems="center">
          <Box display={opened ? '' : 'none'}></Box>
        </Grid>
      </RootGrid>
      {opened && <BlueBox></BlueBox>}

      <LogoBox
        className={'logo-box-scroll-trigger'}
        hover={logoHover}
        m={'3rem'}
        onClick={onLogoClicked}
        onMouseEnter={() => {
          setLogoHover(true)
        }}
        onMouseLeave={() => {
          setLogoHover(false)
        }}
        width={widthDict[width] || '15rem'}
        height={widthDict[width] || '15rem'}
        component="button"
        aria-expanded={opened}
        aria-controls="main-navigation"
      >
        <Logo logoType={opened ? 'NichtNeuHover' : 'NichtNeu'}></Logo>
      </LogoBox>
      {backButton && (
        <Box
          m={3}
          position="fixed"
          top={width === 'xs' ? '11rem' : widthDict[width] || '15rem'}
          right="0"
          width={widthDict[width] || '15rem'}
          height={widthDict[width] || '15rem'}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
        >
          <Cross
            m={{xs: 2, sm: 4}}
            onClick={() => {
              route && navigate(route)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default withWidth()(Navigation)
