import { createMuiTheme } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { rem } from '@/styles/utils'
import { theme } from '@/styles/theme'

// https://material-ui.com/de/customization/theming/
// https://material-ui.com/customization/default-theme/?expand-path=$.typography
// https://material-ui.com/customization/globals/#global-css

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: false
    xl: false
    // tablet: true;
    // desktop: true;
  }
}
//mobile: <1023
//small screens: 1024-1439
//large screens 1600<
const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 1025,
    md: 1600
  }
})

export const mobileMediaQuery = breakpoints.down('xs')
export const desktopMediaQuery = breakpoints.up('sm')

const muiTheme = createMuiTheme({
  spacing: factor => `${factor}rem`,
  breakpoints: breakpoints,
  typography: {
    fontFamily: ['tenon', 'sans-serif'].join(','),
    // "font XXXL"
    h1: {
      fontWeight: 700,
      fontSize: '20vh',
      lineHeight: 1,
      letterSpacing: '0.5px',
      [mobileMediaQuery]: {
        fontSize: '11.5rem'
      }
    },
    // "font XXL"
    h2: {
      fontSize: '5rem',
      fontWeight: 700,
      lineHeight: '5.4rem',
      letterSpacing: '0.5px'
    },
    // "font XL (H1)"
    h3: {
      fontSize: '4rem',
      fontWeight: 700,
      letterSpacing: '0.5px'
    },
    // "font L (H2)"
    h4: {
      fontWeight: 700,
      letterSpacing: '0.6px',
      fontSize: '3rem',
    },
    // "font M Bold (H3 / Body bold)"
    h5: {
      fontWeight: 700,
      letterSpacing: '0.6px',
      fontSize: '2rem'
    },
    // "font S Bold"
    h6: {
      fontSize: '1.5rem',
      fontWeight: 700,
      letterSpacing: '0.5px'
    },
    // "font M (Body)"
    body1: {
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    // "font S"
    body2: {
      fontSize: '1.5rem',
      fontWeight: 400
    },
    // further reading titles
    subtitle1: {
      fontSize: '2.25rem',
      lineHeight: 1.45,
      fontWeight: 800,
      letterSpacing: '0.5px'
    },
    // blog titles
    subtitle2: {
      fontWeight: 700,
      letterSpacing: '0.5px',
      fontSize: '13vh',
      lineHeight: 1,
      marginBottom: rem(11),
      [mobileMediaQuery]: {
        fontSize: '6rem',
        lineHeight: '6rem'
      }
    },
    // "font S Bold + Uppercase"
    button: {
      fontSize: '1.5rem',
      fontWeight: 700,
      letterSpacing: '0.5px',
      textTransform: 'uppercase'
    }
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    type: 'light',
    primary: {
      light: '#7986cb',
      main: '#fff',
      dark: '#303f9f',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff4081',
      main: '#C9F3FF',
      contrastText: '#000'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#000'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    contrastThreshold: 3,
    // getContrastText: f E()
    // augmentColor: f B()
    tonalOffset: 0.2,
    text: {
      primary: '#fff',
      secondary: '#0F46FA',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },

    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#0F46FA',
      default: '#0F46FA'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto'
        }
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: '3rem',
        paddingRight: '3rem'
      }
    },
    MuiLink: {
      root: {
        underline: 'none'
      }
    },
    MuiChip: {
      root: {
        fontSize: '1.5rem',
        fontWeight: 400,
        height: '26px'
      }
    }
  }
})

export default muiTheme
